img.personal {
  height: 40vmin;
  max-height: 230px;
  pointer-events: none;
  border-radius: 50%;
}

img.social {
  height: 5vmin;
  min-height: 25px;
}
